<template>
  <div class="login_box">
    <div class="login-top">
        <van-icon name="cross" size="25"  @click="goblack"/>
        <span @click="show = true">注册账号</span>
    </div>
    <div class="title">
        <div class="text1">密码登录</div>
        <div class="text2">输入手机号和密码验证后登录</div>
    </div>
    <!-- 登入框 -->
    <div class="login_pop">
        <div class="account_div">
            <input class="account" type="number" v-model="params.account" placeholder="请输入手机号">
        </div>
        <div class="password_div">
            <input class="password" type="password" v-model="params.password" placeholder="请输入密码">
        </div>
    </div>
    <div class="last_but">
        <div class="login_but" @click="doLogins">登录</div>
        <!-- <div class="dx_but" @click="goCodeLogin">短信验证码登录</div> -->
        <van-checkbox v-model="checked" checked-color="#CBAA9C" class="checkbox" icon-size="14">
            <tt>我已阅读并同意</tt><span><span @click.stop="openRule(4)">《用户协议》</span><span @click.stop="openRule(5)">《隐私保护指引》</span></span>
        </van-checkbox>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
        <div class="help_pop">
            <div @click="goRegister">去注册</div>
            <div @click="gobackPassword">忘记密码</div>
        </div>
        <div @click="show = false" class="help_close">取消</div>
    </van-popup>
    <van-popup class="ruletext" v-model="ruleShow">
        <div class="reletext" v-html="ruleText"></div>
        <div class="butann">
            <van-button type="default" @click="doReject">拒绝</van-button>
            <van-button type="warning" @click="doAgree">同意</van-button>
        </div>
    </van-popup>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { getRule, userLogin } from '@/api/user' // 协议内容 登录
export default {
  name: "loginIndex",

  data() {
    return {
        checked: false,
        ruleText: '', // 协议内容
        ruleShow: false,
        rule4: false, // 是否查看视商协议
        rule5: false, // 是否查看隐私保护指引
        isdisabled: true,
        show: false,
        params: {
            account: "",
            password: ""
        },
        ruleId: 0,
    };
  },

  mounted() {},

  methods: {
    // 查看协议
    openRule(id) {
        this.ruleShow = true
        this.ruleId = id
        // id == 4?this.rule4 = true:this.rule5 = true
        // if (this.rule4&&this.rule5){
        //     this.isdisabled = false
        // }
        getRule(id).then(res => {
            this.ruleText = res.data
        })
    },
    // 点击同意
    doAgree() {
        this.ruleId == 4?this.rule4 = true:this.rule5 = true
        if (this.rule4&&this.rule5){
            this.isdisabled = false
        }
        this.ruleShow = false
    },
    // 点击拒绝
    doReject() {
        this.ruleId == 4?this.rule4 = false:this.rule5 = false
        if (this.rule4&&this.rule5){
            this.isdisabled = false
        } else {
           this.isdisabled = true 
        }
        this.ruleShow = false
    },
    // 登录
    doLogins() {
        var that = this
        if (!that.checked) {
            return that.$layer.quicktips('请阅读并同意协议')
        }
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.account)) {
            return that.$layer.quicktips('请输入正确的手机号')
        }
        if (!that.params.password) {
            return that.$layer.quicktips('请输入密码')
        }
        this.$store.dispatch('Login', this.params).then((response) => {
            if (response.state == 200) {
                that.$layer.quicktips('登录成功')
                that.$router.push('../homePage/index')
            }
        })
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    goRegister() {
        this.$router.push('./register')
    },
    gobackPassword() {
        this.$router.push('./backPassword')
    },
    // 短信验证码登录
    // goCodeLogin() {
    //     this.$router.push('./codeLogin')
    // }
  },
};
</script>

<style lang="less" scoped>
.ruletext{
    width: 75%;
    height: 60%;
    padding: 20rem;
    font-size: 14rem;
    line-height: 1.7;
}
.login_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
}
.login-top{
    display: flex;
    justify-content: space-between;
    padding: 15rem 17rem;
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #333333;
    }
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.login_pop{
    padding: 30rem 32rem;
    input{
        border: none;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 18rem;
        color: #333333;
    }
    .account{
        padding: 10rem 0rem;
    }
    .password{
        padding: 10rem 0rem;
    }
}
.account_div{
    border-bottom: 1rem solid #F5F6FB;
}
.password_div{
    border-bottom: 1rem solid #F5F6FB;
}
.last_but{
    margin-top: 40rem;
    padding: 0rem 33rem;
    .login_but{
        height: 49rem;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
    }
    .dx_but{
        height: 49rem;
        background: #F2F3FC;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
        margin-top: 12rem;
    }
    .checkbox{
        margin-top: 12rem;
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #8D9399;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #CBAA9C;
        }
    }
}
.van-popup{
    background: #F4F3F8;
}
.help_pop{
    width: 100vw;
    background: #fff;
    div{
        padding: 23rem 0rem ;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #444444;
    }
}
.help_close{
        width: 100vw;
        background: #fff;
        padding: 23rem 0rem ;
        text-align: center;
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #A4ABB1;
}
.reletext{
    height: calc(100% - 30rem);
    overflow-y: auto;
}
.butann{
    text-align: center;
    // background: #fff;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    .van-button{
        margin-left: 10rem;
    }
}
</style>
